import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Layout, breakpoints, Text, Link, Modal, Button } from "@alj-react/ui-components";
import styled, { css } from "styled-components";
import DataTable, { DataTableColumnType } from "../../../organisms/DataTable";
import { dateToString } from "../../../../utils/dateFormatter";
import axios from "axios";
import { useQuery } from "react-query";

enum TabPage {
  CONFIRMATION = "CONFIRMATION",
  HISTORY = "HISTORY",
}

declare const window: Window;
const apiHost = process.env.REACT_APP_LAMBDA ?? '';
const logoutUrl = process.env.REACT_APP_LOGOUT_URL ?? '';
const logoutCallbackUrl = process.env.REACT_APP_LOGOUT_CALLBACK_URL ?? '';
const sessionTimeoutMinute = parseInt(process.env.REACT_APP_SESSION_TIMEOUT_MINUTE?? '60');
const sessionTimeoutMilliSec = sessionTimeoutMinute * 1000 * 60;
const groupInsuranceUrl = process.env.REACT_APP_GROUP_INSURANCE_URL ?? '';

const InsuranceCollectiveHome: React.FC = () => {
  const [cookies] = useCookies(["auth0Token", "auth0RefreshToken"]);
  const [tabPage, setTabPage] = useState(TabPage.CONFIRMATION);
  const [answerDate] = useState<string>(dateToString(new Date()));
  const [confirming, setConfirming] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [answerData, setAnswerData] = useState<{[id: string]: boolean}>({});
  const [requireCompletedEmail, setRequireCompletedEmail] = useState<boolean>(true);
  
  const profileQuery = useQuery('findProfile', () => apiRequest('get', `${apiHost}/insurance/collective/api/getProfile`));
  const confirmationDataQuery = useQuery('findConfirmationData', () => apiRequest('get', `${apiHost}/insurance/collective/api/getPending`));
  const historyDataQuery = useQuery('findHistoryData', () => apiRequest('get', `${apiHost}/insurance/collective/api/getHistory`));

  const submitDisabled = Object.keys(answerData).length === 0 || submitting || confirmationDataQuery.isFetching;

  const toggleAnswer = (checkbox: HTMLInputElement, row: any, answer: boolean) => {

    const newAnswerData = structuredClone(answerData);

    if (newAnswerData[row.confirmationContentId] === answer) {
      delete newAnswerData[row.confirmationContentId];
    } else {
      newAnswerData[row.confirmationContentId] = answer;
    }

    setAnswerData(newAnswerData);

    const oppositeCheckboxList = checkbox.parentElement?.parentElement?.getElementsByClassName(answer ? "confirmation-table-answer-no" : "confirmation-table-answer-yes");

    if (oppositeCheckboxList && oppositeCheckboxList?.item(0)) {
      const oppositeCheckbox = oppositeCheckboxList?.item(0) as HTMLInputElement;
      oppositeCheckbox.checked = false;
    }
  };

  const confirmationColumns: DataTableColumnType[] = [
    {
      title: "",
      isId: true,
      data: "confirmationContentId",
      hidden: true,
    },
    {
      title: "回答欄 ",
      data: "isGroupMember",
      width: "14%",
      dataRendering: (data, _dataKey, row, rowId) => (
        <AnswerRow>
          <AnswerContainer>
            <input id={`confirmation-table-answer-yes-${rowId}`} className="confirmation-table-answer-yes" type="checkbox" defaultChecked={answerData[rowId]===true} onChange={(e) => toggleAnswer(e.target, row, true)} />
            <label htmlFor={`confirmation-table-answer-yes-${rowId}`}>所属</label>
          </AnswerContainer>
          <AnswerContainer>
            <input id={`confirmation-table-answer-no-${rowId}`} className="confirmation-table-answer-no" type="checkbox" defaultChecked={answerData[rowId]===false} onChange={(e) => toggleAnswer(e.target, row, false)} />
            <label htmlFor={`confirmation-table-answer-no-${rowId}`}>非所属</label>
          </AnswerContainer>
        </AnswerRow>
      ),
    },
    {
      title: "回答\n依頼日",
      data: "requestDate",
      width: "8%",
      dataAlign: "center",
      dataRendering: (data) => data.replaceAll("-", "/"),
    },
    {
      title: "依頼\n番号",
      data: "representativeDocNo",
      width: "9%",
      dataRendering: (data) => `${data.substring(0,3)}-${data.substring(3,7)}-${data.substring(7,8)}-\n${data.substring(8)}`,
    },
    { title: "契約者名 ", data: "plcyhldrName", width: "12%" },
    { title: "事業所名 （勤務先名）", data: "jigyoshoShozokuName", width: "16%", dataRendering: (data, _dataKey, row) => (row["plcyFormKb"] === "0" ? data : row["plcyhldrName"]) },
    { title: "事業所住所", data: "jigyoshoAddr", width: "20%", dataRendering: (data, _dataKey, row) => (row["plcyFormKb"] === "1" ? data : "") },
    {
      title: "事業所番号\n（その他番号）",
      data: "jigyoshoShozoku3",
      width: "12%",
      dataRendering: (data, _dataKey, row) => (!!row["groupOtherNo"] ? `${(data??"")}\n(${row["groupOtherNo"]})` : (data??"")),
    },
    { title: "取扱者名", data: "agentName1", width: "9%", dataRendering: (data, _dataKey, row) => (!!row["agentName2"] ? `${data}\n${row["agentName2"]}` : data) },
  ];

  const historyColumns: DataTableColumnType[] = [
    {
      title: "",
      isId: true,
      data: "confirmationContentId",
      hidden: true,
    },
    {
      title: "回答欄 ",
      data: "isGroupMember",
      width: "10%",
      dataRendering: (data, _dataKey, _row, rowKey) => (
        <AnswerRow>
          <AnswerContainer>
            <input id={`history-table-answer-yes-${rowKey}`} type="checkbox" defaultChecked={data === "1"} disabled />
            <label htmlFor={`history-table-answer-yes-${rowKey}`}>所属</label>
          </AnswerContainer>
          <AnswerContainer>
            <input id={`history-table-answer-no-${rowKey}`} type="checkbox" defaultChecked={data === "0"} disabled />
            <label htmlFor={`history-table-answer-no-${rowKey}`}>非所属</label>
          </AnswerContainer>
        </AnswerRow>
      ),
    },
    {
      title: "回答\n依頼日",
      data: "requestDate",
      width: "8%",
      dataAlign: "center",
      dataRendering: (data) => data.replaceAll("-", "/"),
    },
    { 
      title: "依頼\n番号",
      data: "representativeDocNo",
      width: "9%",
      dataRendering: (data) => `${data.substring(0,3)}-${data.substring(3,7)}-${data.substring(7,8)}-\n${data.substring(8)}`,
    },
    { title: "契約者名 ", data: "plcyhldrName", width: "9%" },
    { title: "事業所名\n（勤務先名）", data: "jigyoshoShozokuName", width: "12%", dataRendering: (data, _dataKey, row) => (row["plcyFormKb"] === "0" ? data : row["plcyhldrName"]) },
    { title: "事業所住所", data: "jigyoshoAddr", width: "11%", dataRendering: (data, _dataKey, row) => (row["plcyFormKb"] === "1" ? data : "") },
    {
      title: "事業所番号\n（その他番号）",
      data: "jigyoshoShozoku3",
      width: "9%",
      dataRendering: (data, _dataKey, row) => (!!row["groupOtherNo"] ? `${(data??"")}\n(${row["groupOtherNo"]})` : (data??"")),
    },
    { title: "取扱者名", data: "agentName1", width: "10%", dataRendering: (data, _dataKey, row) => (!!row["agentName2"] ? `${data}\n${row["agentName2"]}` : data) },
    { title: "確認者", data: "confirmer", width: "14%" },
    { title: "確認日", data: "confirmDate", width: "8%", dataAlign: "center", dataRendering: (data) => data.replaceAll("-", "/") },
  ];

  const authenticationReguestConfig = () => ({
    headers: { Authorization: `${cookies.auth0Token},${cookies.auth0RefreshToken}` },
  });

  const apiRequest = async (method: 'get'|'put'|'post', url: string, data: any = null) => {
    try {
      if (method === 'get') {
        return (await axios.get(url, authenticationReguestConfig())).data;
      } else if (method === 'put') {
        return (await axios.put(url, data, authenticationReguestConfig())).data;
      } else if (method === 'post') {
        return (await axios.post(url, data, authenticationReguestConfig())).data;
      }
    } catch (e: any) {
      if (e.response) {
        if ([401,403].includes(e.response.status)) {
          window.location.href = '/insurance/collective/home';
        }
        throw e;
      }
    }
  }

  const reloadAllData = async () => {
    confirmationDataQuery.refetch();
    historyDataQuery.refetch();
  }

  const submit = async () => {
    setSubmitting(true);
    try {
      const answeredContentIdList = Object.keys(answerData);
      if (answeredContentIdList.length === 0) return;
      
      const data = Object.keys(answerData)
        .map((confirmationContentId) => ({ confirmationContentId, isGroupMember: answerData[confirmationContentId] }));
      
      await apiRequest('put', `${apiHost}/insurance/collective/api/confirm`, {
        requireCompletedEmail: requireCompletedEmail,
        data: data,
      });
      setAnswerData({});
      reloadAllData();
    } catch (e) {
      console.log(e);
    }
    setConfirming(false);
    setSubmitting(false);
  };

  const openManual = () => {
    const popupWindow = window.open(
      '/insuranceCollective/manual.html', 
      "AxaManualList",
      "popup=yes,top=50,left=100,width=800,height=600,menubar=0,toolbar=0,resizable=1,scrollbars=1"
    );
    popupWindow?.focus();
  }

  const ResetLogoutTimer = () => {
    return setTimeout(() => {
      window.location.href = `${logoutUrl}?id_token_hint=${encodeURIComponent(cookies.auth0Token)}&post_logout_redirect_uri=${encodeURIComponent(logoutCallbackUrl)}`;
    }, sessionTimeoutMilliSec);
  }
  
  useEffect(() => {

    document.title = "団体扱集団扱確認システム";

    let logoutTimer = ResetLogoutTimer();

    window.onclick = () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      logoutTimer = ResetLogoutTimer();
    };
  }, []);

  return (
    <LayoutStyled>
      <NavBar>
        <ProfileName><CustomText size="24">団体名    {
          confirmationDataQuery.data && confirmationDataQuery.data.length > 0 ? confirmationDataQuery.data[0].groupCustName : 
          historyDataQuery.data && historyDataQuery.data.length > 0 ? historyDataQuery.data[0].groupCustName : ''
        }</CustomText></ProfileName>
        <NavMenuContainer>
          <CustomText><Link to={groupInsuranceUrl} useRouter={false} target="_blank">AXA団体保険ウェブへ</Link></CustomText>
          <CustomText><Link onClick={() => openManual()}>マニュアル</Link></CustomText>
        </NavMenuContainer>
      </NavBar>
      <CustomText color="axaBlue" size="28">
        団体扱集団扱　確認システム
      </CustomText>
      {tabPage === TabPage.CONFIRMATION && (
        <PageSubject color="axaBlue" size="18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;アクサ生命の保険契約を団体扱・集団扱としてお申込みいただくにあたり、下記の契約者または下記の契約者が勤務する事業所が確認日現在、貴団体の所属員であることをご確認ください。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;回答欄にてご回答のうえ、「回答する」ボタンを押してください。所属と回答いただいた場合、アクサ生命では団体扱・集団扱いとして取扱させていただきます。
        </PageSubject>
      )}
      {tabPage === TabPage.HISTORY && (
        <PageSubject color="axaBlue" size="18">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;下記は直近3ヵ月の回答履歴を表示しております。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;回答画面に戻る場合は　〇回答画面　を押してください。
        </PageSubject>
      )}
      <RadioButtonRow>
        <RadioButtonContainer>
          <input id="insurance-collective-confirmation" name="insurance-collective-confirmation-tab" type="radio" value={"confirmation"} defaultChecked={tabPage === TabPage.CONFIRMATION} onClick={(_e) => setTabPage(TabPage.CONFIRMATION)} />
          <label htmlFor={"insurance-collective-confirmation"}>回答画面</label>
        </RadioButtonContainer>
        <RadioButtonContainer>
          <input id="insurance-collective-confirmation-history" name="insurance-collective-confirmation-tab" type="radio" value={"history"} defaultChecked={tabPage === TabPage.HISTORY} onClick={(_e) => setTabPage(TabPage.HISTORY)} />
          <label htmlFor={"insurance-collective-confirmation-history"}>回答履歴</label>
        </RadioButtonContainer>
      </RadioButtonRow>
      <ReloadButtonContainer>
        <ReloadButton color="blue-ghost" onClick={() => reloadAllData()}>更新</ReloadButton>
      </ReloadButtonContainer>
      <TableContainer>
        <div style={{ display: tabPage === TabPage.CONFIRMATION ? "initial" : "none" }}>
          {
            confirmationDataQuery.isFetching 
            ? <DataTableLoading>回答依頼を読み込み中...</DataTableLoading>
            : confirmationDataQuery.isSuccess
            ? <DataTable columns={confirmationColumns} tableStyle={{scrollWidth: 1024}} data={confirmationDataQuery.data} emptyDataTxt="回答依頼がありません" /> 
            : <DataTableLoading>回答依頼を読み込み失敗</DataTableLoading>
          }
          <BottomInstruction>
            <CustomText size="14">上記の回答において、所属（会員）または非所属（非会員）であることを回答いたします。</CustomText>
            <BottomSubInfo>
              <CustomText size="14">回答日：{answerDate}</CustomText>
            </BottomSubInfo>
            <BottomSubInfo>
              <CustomText size="14">回答者メールアドレス：{profileQuery.isSuccess ? profileQuery.data.email : ''}</CustomText>
            </BottomSubInfo>
            <BottomCheckboxRow>
              <CustomText size="14">
              <input id='requireCompletedEmail' type="checkbox" checked={requireCompletedEmail} onChange={(e) => setRequireCompletedEmail(!requireCompletedEmail)} />
              <label htmlFor='requireCompletedEmail'>回答後に完了メールを受け取る（完了メールを受け取らない場合はチェックを外してください）</label>
              </CustomText>
            </BottomCheckboxRow>
          </BottomInstruction>
          <SubmitContainer>
            <CustomButton disabled={submitDisabled} loading={submitting} onClick={() => setConfirming(true)}>
              回答する
            </CustomButton>
          </SubmitContainer>
        </div>
        <div style={{ display: tabPage === TabPage.HISTORY ? "initial" : "none" }}>
        {
            historyDataQuery.isFetching 
            ? <DataTableLoading>回答履歴を読み込み中...</DataTableLoading>
            : historyDataQuery.isSuccess
            ? <DataTable columns={historyColumns} tableStyle={{scrollWidth: 1024}} data={historyDataQuery.data} emptyDataTxt="回答履歴がありません" /> 
            : <DataTableLoading>回答履歴を読み込み失敗</DataTableLoading>
          }
        </div>
      </TableContainer>
      <Modal showModal={confirming} onClose={() => setConfirming(false)}>
        <CustomText>ご入力の内容で回答してもよろしいでしょうか？</CustomText>
        <div>
          <ConfirmContainer>
            <CustomButton disabled={submitDisabled} onClick={() => submit()} loading={submitting}>
              はい
            </CustomButton>
          </ConfirmContainer>
          <ConfirmContainer>
            <CustomButton color="orange" onClick={() => setConfirming(false)} loading={submitting}>いいえ</CustomButton>
          </ConfirmContainer>
        </div>
      </Modal>
    </LayoutStyled>
  );
};

const LayoutStyled = styled(Layout)`
  font-family: 'Lucida Grande';
`;

const NavBar = styled.div`
  height: 6rem;
  margin-top: -6rem;
  text-align: right;
`

const CustomText = styled(Text)`
  font-family: 'Lucida Grande';
`

const ProfileName = styled.div`
  display: inline-block;
  white-space: pre;
  margin-right: 3rem;

  ${breakpoints.smallOnly(css`
    margin-right: 1rem;
  `)}
`

const NavMenuContainer = styled.div`
  display: inline-block;
`

const PageSubject = styled(CustomText)`
  white-space: pre-wrap;
`
const DataTableLoading = styled.div`
  text-align: center;
  padding: 3rem;
`

const RadioButtonRow = styled.div`
  display: flex;
  justify-content: center;
`;

const RadioButtonContainer = styled.div`
  flex-basis: 15%;
  text-align: center;

  ${breakpoints.smallOnly(css`
    flex-basis: 18%;
  `)}
`;

const AnswerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
`;

const AnswerContainer = styled.div`
  font-family: 'Lucida Grande';
  display: flex;
  align-items: center;
  width: 3.75rem;
`;

const TableContainer = styled.div`
  font-family: 'Lucida Grande';
  margin-top: 2rem;
`;

const BottomInstruction = styled.div`
  margin-top: 1rem;
  padding-left: 2.5rem;
`;

const BottomSubInfo = styled.div`
  margin-right: 2rem;
  display: inline-block;
`;

const BottomCheckboxRow = styled(BottomSubInfo)`
  margin-left: -0.25rem;
`;

const SubmitContainer = styled.div`
  float: right;
  margin-bottom: 2rem;
`;

const ConfirmContainer = styled.div`
  float: right;
  margin-top: 1.5rem;
  margin-left: 2rem;
`;

const CustomButton = styled(Button)`
  font-family: 'Lucida Grande';
`

const ReloadButtonContainer = styled.div`
  text-align: right;
  margin-top: -1.75rem;
  margin-bottom: -1.5rem;
`

const ReloadButton = styled(Button)`
  font-family: 'Lucida Grande';
`

export default InsuranceCollectiveHome;
